<template>
  <page-view class="designcom-all" v-infinite-scroll="loadScroll" :infinite-scroll-disabled="disabled"
    :infinite-scroll-immediate="false" :up-class="'designcom-all'">
    <div class="searcr">
      <el-input placeholder="请输入公司名称" v-model.trim="input" @change="searchInput" clearable>
        <el-button slot="append" icon="el-icon-search" @click="searchInput"></el-button>
      </el-input>
    </div>

    <div class="com-list">
      <div class="com-item" v-for="item in allComList" :key="item.id">
        <div class="item-title" @click="toComIndex(item.syscompanyid)">
          <div class="item-title-left">
            <div class="item-name">
              {{ item.name }}
            </div>
            <div class="item-describle" v-html="Array.isArray(item.cmsArticle4)
              ? item.cmsArticle4[0]?.content
              : ''
              "></div>
          </div>
          <div class="item-title-right">
            <img :src="item.logo" @click="toComIndex(item.syscompanyid)" alt="" />
          </div>
        </div>
        <!-- <div class="item-imgs-title" v-if="Array.isArray(item.cmsArticle2) && item.cmsArticle2.length > 0">
          优秀案例
        </div>
        <div class="item-imgs-title" v-else-if="Array.isArray(item.cmsArticle1) && item.cmsArticle1.length > 0">
          企业产品
        </div> -->
        <div class="item-imgs" v-if="item.cmsArticle2 && item.cmsArticle2.length > 0">
          <div class="img" @click="toDetail(caseItem, item.cmsArticle2)" v-for="caseItem in item.cmsArticle2.slice(0, 4)"
            :key="caseItem.id">
            <img :src="caseItem.image" height="100%" width="100%" alt="" />
            <p class="case-title">{{ caseItem.title }}</p>
          </div>
        </div>
        <div class="item-imgs" v-else-if="item.cmsArticle1 && item.cmsArticle1.length > 0">
          <div class="img" @click="toDetail(caseItem, item.cmsArticle1)" v-for="caseItem in item.cmsArticle1.slice(0, 4)"
            :key="caseItem.id">
            <img :src="caseItem.image" height="100%" width="100%" alt="" />
            <p class="case-title">{{ caseItem.title }}</p>
          </div>
        </div>
      </div>
    </div>
    <el-empty description="暂无数据" style="min-height: 385px" v-if="allComList.length < 1"></el-empty>
    <div class="load-scroll">
      <center v-if="loading">
        <div class="bouncing-bar">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </center>
      <center v-if="noMore && allComList.length > 0">没有更多了</center>
    </div>
    <footer-com></footer-com>
  </page-view>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "DesignComAll",
  data() {
    return {
      input: "",
      allComList: [],
      total: 0, //总条数
      current: 1, //当前页,
      loading: false,
      companyType: 2,
    };
  },
  computed: {
    ...mapState(["search"]),
    noMore() {
      return this.allComList.length >= this.total;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  watch: {
    search: {
      handler(newValue) {
        this.companyType = this.$route.params.companyType;
        this.input = newValue.value;
        //修改网页标题
        let title = "百川商展";
        document.title = this.input ? `${title}-${this.input}` : `${title}`;
        this.getAllCom();
      },
      deep: true,
    },
  },
  created() {
    this.companyType = this.$route.params.companyType;
    this.input = this.$route.params.name;
    //修改网页标题
    let title = "百川商展";
    switch (this.companyType) {
      case 2:
        title = "百川商展-设计优选";
        break;
    }
    document.title = this.input ? `${title}-${this.input}` : `${title}`;
    this.getAllCom();
  },
  methods: {
    ...mapActions(["setTheArticle", "setTheArticleList"]),
    async getAllCom() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.current = 1;
      let result = await this.$api.selectSn({
        bc_company_type: this.companyType,
        current: this.current,
        size: 4,
        name: this.input,
      });
      loading.close();
      this.allComList = result.data;
      console.log(this.allComList);
      this.total = result.page.total;
      // this.allComList.forEach((item) => {
      //   if (Array.isArray(item.cmsArticle2)) {
      //     item.cmsArticle2 = item.cmsArticle2.slice(0, 3);
      //   }
      // });
    },
    async loadScroll() {
      if (this.disabled) {
        return false; //没有更多了
      }
      this.loading = true;
      this.current++;
      let result = await this.$api.selectSn({
        bc_company_type: this.companyType,
        current: this.current,
        size: 4,
        name: this.input,
      });
      this.loading = false;
      this.allComList.push(...result.data);
      // this.allComList.forEach((item) => {
      //   if (Array.isArray(item.cmsArticle2)) {
      //     item.cmsArticle2 = item.cmsArticle2.slice(0, 3);
      //   }
      // });
    },
    searchInput() {
      //修改网页标题
      let title = "百川商展";
      document.title = this.input ? `${title}-${this.input}` : `${title}`;
      this.getAllCom();
    },
    toDetail(item, _arr) {
      this.setTheArticle(item);
      // 修改文章列表
      this.setTheArticleList(_arr);
      const openRoute = this.$router.resolve({
        name: "ExcellentDesign",
      });
      window.open(openRoute.href, "_blank");
    },
    toComIndex(syscompanyid) {
      const openRoute = this.$router.resolve({
        name: "ComIndex",
        params: { syscompanyid },
      });
      window.open(openRoute.href, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.designcom-all {
  flex: 1;
  overflow: auto;
  background-color: #f5f5f5;

  .searcr {
    @include flexbox(row, center, center);
    margin: 20px 0;

    :deep(.el-input) {
      width: 40%;

      @media screen and (max-width: 1200px) {
        width: 90%;
      }

      .el-input__inner {
        box-shadow: 0px 20px 20px 0px rgba(70, 156, 76, 0.1);
        border-radius: 45px 0 0 45px;
        border: 1px solid #469c4c;
        border-right: none;
      }

      .el-input-group__append {
        background-color: #fff;
        color: #469c4c;
        font-size: 18px;
        border: 1px solid #469c4c;
        border-left: none;
        border-radius: 0 45px 45px 0;
      }
    }
  }

  .com-list {
    margin-top: 2vw;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;

    .com-item {
      background-color: #fff;
      margin-bottom: 3%;
      padding: 2% 4%;
      box-sizing: border-box;

      .item-title {
        @include flexbox(row);
        border-bottom: 3px solid #e6e4e4;
        padding: 20px 0;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        gap: 5%;

        .item-title-left {
          flex: 1;
          .item-name {
            font-size: 1.5vw;
            cursor: pointer;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1; //想显示多少行
            color: #439c4c;
            font-weight: bold;
          }

          .item-describle {
            color: #7f7f7f;
            margin-top: 20px;
            overflow: hidden; //超出的文本隐藏
            display: -webkit-box;
            -webkit-line-clamp: 2; // 超出多少行
            -webkit-box-orient: vertical;
            cursor: pointer;
            white-space: unset;
            line-height: 2;

            :deep(img) {
              display: none;
            }
          }

          .item-tip {
            margin: 20px 0;

            >span {
              background-color: #e3f4ec;
              color: $primaryColor;
              font-size: 12px;
              margin-right: 15px;
              padding: 4px 15px;
              border-radius: 2px;
            }
          }

          .item-case {
            color: #7f7f7f;
            font-size: 12px;
          }
        }

        .item-title-right {
          position: relative;
          width: 6vw;
          height: 6vw;
          &::after{
            content: '';
            position: absolute;
            top: 50%;
            left: -50%;
            transform: translate(-50%,-50%);
            height: 120%;
            width: 2px; 
            background-color: #e6e4e4;
          }

          >img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            object-fit: contain;
            cursor: pointer;
          }
        }
      }

      .item-imgs-title {
        font-size: 14px;
        color: #333;
        padding-top: 14px;
        font-weight: bold;
      }

      .item-imgs {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 4%;
        padding: 2% 0%;

        :deep(.el-skeleton__image) {
          border-radius: 4px;
        }

        .img {
          cursor: pointer;

          >img {
            transition: all 0.2s linear;
            aspect-ratio: 2/1.3;
            height: 15vw;
            object-fit: cover;

            &:hover {
              transform: scale(1.05);
            }
          }
        }

        .case-title {
          font-size: 1vw;
          text-align: center;
          margin-top: 4%;
          display: -webkit-box;
          overflow: hidden; //超出隐藏
          text-overflow: ellipsis; //隐藏后添加省略号
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1; //想显示多少行
        }
      }
    }
  }

  .load-scroll {
    padding: 30px 0;
    margin-bottom: 50px;

    .bouncing-bar {
      display: flex;
      justify-content: center;
    }

    .line {
      height: 20px;
      width: 4px;
      background: #439c4c;
      margin-right: 4px;
      border-radius: 6px;
      animation: line-bounce 1s infinite ease-in-out;

      &:nth-child(1) {
        animation-delay: 0s;
      }

      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation-delay: 0.3s;
      }

      &:nth-child(4) {
        animation-delay: 0.4s;
      }
    }

    @keyframes line-bounce {
      0% {
        transform: scale(1);
      }

      20% {
        transform: scale(1, 2);
      }

      40% {
        transform: scale(1);
      }
    }
  }
}
</style>
